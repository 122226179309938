@import url("https://use.typekit.net/vci7jvh.css");

/* https://res.cloudinary.com/dgps1radv/image/upload/c_fill,w_200/mto5squ8abpmd02zymev */
$red: #cb0004;
$black: #222;
$borderWidth: 4px;
$paddingY: 0.35em;

$sigFont: mr-eaves-xl-modern-narrow, sans-serif;
$sigFontCompressed: mr-eaves-xl-modern-narrow, sans-serif;
$deco: reiner-script, sans-serif;
// $sigFont: expressway, sans-serif;
.ordering-menu {
  // background: $black;
  /* background: none; */
  // color: white;
}

.hayats {
  font-family: $sigFontCompressed;
  background-color: #f4f4f4;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%23e1e1e1' fill-opacity='0.4' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E");
  .landing-page-wrapper {
    max-width: 900px;
    margin: 0 auto;
  }
  .delivery-note {
    font-family: $sigFontCompressed;
    font-size: 0.6em;
  }
  a.checkout-button.button {
    // background: none !important;
    // color: $red;
    text-transform: capitalize;
    // display: inline-block;
    // border-bottom: 4px solid $red !important;
    padding: 0em 0.3em !important;
    // border-radius: none !important;
    font-size: 1.1em;
    // font-weight: 900 !important;
  }
  .pages-nav {
    max-width: 900px;
    margin: 0 auto;
  }
  img.nav-bar-logo {
    display: block;
    position: relative;
    height: 45px;
    // bottom: -0.1em;
    padding-left: 0.4em;
    // padding-bottom: 0.2em;
  }

  .cart.minimized {
    max-width: 900px;
    margin: 0 auto;
    padding-left: 0;
    background-color: #f4f4f4;
background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%23e1e1e1' fill-opacity='0.4' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E");
//     background-color: #f2fff8;
// background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 80 40' width='80' height='40'%3E%3Cpath fill='%23d6e3c0' fill-opacity='0.4' d='M0 40a19.96 19.96 0 0 1 5.9-14.11 20.17 20.17 0 0 1 19.44-5.2A20 20 0 0 1 20.2 40H0zM65.32.75A20.02 20.02 0 0 1 40.8 25.26 20.02 20.02 0 0 1 65.32.76zM.07 0h20.1l-.08.07A20.02 20.02 0 0 1 .75 5.25 20.08 20.08 0 0 1 .07 0zm1.94 40h2.53l4.26-4.24v-9.78A17.96 17.96 0 0 0 2 40zm5.38 0h9.8a17.98 17.98 0 0 0 6.67-16.42L7.4 40zm3.43-15.42v9.17l11.62-11.59c-3.97-.5-8.08.3-11.62 2.42zm32.86-.78A18 18 0 0 0 63.85 3.63L43.68 23.8zm7.2-19.17v9.15L62.43 2.22c-3.96-.5-8.05.3-11.57 2.4zm-3.49 2.72c-4.1 4.1-5.81 9.69-5.13 15.03l6.61-6.6V6.02c-.51.41-1 .85-1.48 1.33zM17.18 0H7.42L3.64 3.78A18 18 0 0 0 17.18 0zM2.08 0c-.01.8.04 1.58.14 2.37L4.59 0H2.07z'%3E%3C/path%3E%3C/svg%3E");
    // padding-bottom: 0.35em;
    // background: white;
    border: none;
    // border-bottom: 2px solid black;
  }
  .hero-banner {
    background: url(https://afag.imgix.net/hayats-kitchen/hero-white.jpg?w=1000) no-repeat;
    background-size: cover;
    min-height: 250px;
    background-position: center 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    text-shadow: 1px 1px 1px solid black;
    .text {
      font-size: 1.8em;
      padding: 1em 0 0 0.3em;
      font-family: $deco;
      font-weight: 600;
    }
  }

  .location-info {
    background-color: #f4f4f4;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%23e1e1e1' fill-opacity='0.4' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E");
    padding: 1em 0.5em;
    text-align: center;
    h2 {
      font-family: $deco;
      font-size: 2em;
      margin-top: 0.5em;
    }
    p {
      margin: 0;
    }
  }
  .landing-page, .top-banner {

    font-family: $sigFont;
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0 auto;
    display: block;

    min-height: 250px;
    /* max-height: 50vh; */
    // background: url(https://image.shutterstock.com/z/stock-photo-chinese-food-on-dark-wooden-table-noodles-fried-rice-dumplings-stir-fry-chicken-dim-sum-466836857.jpg);
    // background: url(https://image.shutterstock.com/z/stock-photo-assorted-chinese-food-set-chinese-noodles-fried-rice-dumplings-peking-duck-dim-sum-spring-446808100.jpg);
    // background: url(https://afag.imgix.net/hayats-kitchen/hero-1.jpg?w=1400);
    background: url(https://afag.imgix.net/hayats-kitchen/la-times-placeholder.jpeg?w=1000);
    // background: url(https://farm9.staticflickr.com/8035/7992923523_73b6c2a4ef_h.jpg);
    background-size: cover;
    background-position: center 80%;
    display: flex;
    text-shadow: 1px 1px 1px solid black;
    // align-items: center;
    // justify-content: flex-start;
    font-size: 0.9em;
    h2 {
      font-family: $deco;
      font-weight: 600;
      font-size: 1.5em;
    }
    .attribution {
      line-height: 1.3em;
      font-style: italic;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .la-times-logo {
        display: inline-block;
        margin-left: 0.3em;
      }
    }
  }
  .order-benefits-wrapper {
    // min-height: 200px;
    // background: url(https://afag.imgix.net/hayats-kitchen/hero-1.jpg?w=1200) no-repeat;
    background-size: cover;
    background-position: center 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text {
      color: white;
      background: black;
      padding: 0.2em 0.4em;
    }
  }
  .landing-page .text {
    color: white;
    text-shadow: 1.5px 1.5px 1.5px black;
    padding: 0.5em 0.8em;
    max-width: 360px;
  }

  a.checkout-button.button {
    background: $red;

    font-weight: 600;
    box-shadow: none;
    border: 2px solid #eee;

  }

  /* .category__header {
  font-family: cursive;

} */
.category-name .text {
  color: black;

  font-family: $deco;
  font-weight: 600;
  font-size: 2.2em;
  line-height: 0.7em;
  padding: 0.3em 0.3em 0.2em 0.3em;
  border-radius: 3px;
  background: none;
  position: relative;


}
.menu-category-list-view {
  .food-name {
    font-weight: 600 !important;
    // font-family: $deco;
    // font-size: 1.2em;
  }
  .content {
    font-size: 1.3em;
    width: 70%;
  }
}

.top-banner {
  // height: 30vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  color: white;
  text-shadow: 1px 1px 0 $red;
}

.pages-nav {
  font-family: $sigFont;
  // background: black;
  margin-top: 0.5em;
  ul {
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 480px) {
    font-size: 4vw;
  }
}

a.page-link {
  text-decoration: none;
  color: black;
  display: block;
  // padding: $paddingY 0.3em;
  // padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
  padding: 0.2em 0.3em;
  // box-sizing: border-box;
  // border-bottom: $borderWidth solid black;
  &:hover, &.active {
    background: $red;
    color: white;
    border-radius: 0.1em;
    // border-bottom: $borderWidth solid $red;
  }
}
a.order-online-button {
  // margin: 2em 0;
  font-size: 0.5em;
  display: inline-block;
  color: white;
  text-decoration: none;
  padding: 0.3em 0.5em;
  background: $red;
  font-family: $sigFontCompressed;
  font-weight: 600;
  box-shadow: none;
  // margin-right: 0.5em;
  // margin: 0.5em;
  // margin-left: 0;
  border-radius: 15px;
}
a.order-from-old {
  font-size: 0.4em;
  display: inline-block;
  color: white;
  text-decoration: none;
  padding: 0.3em 0.5em;
  background: black;
  font-family: $sigFontCompressed;
  font-weight: 600;
  box-shadow: none;
  // margin-right: 0.5em;
  // margin: 0.5em;
  // margin-left: 0;
  border-radius: 15px;
}

.full-menu-link-wrapper {
  padding: 1em 0;
  text-align: center;
  a.button {
    background: $red;
    color: white;
  }
}
  .menu-item {
    header {
      // font-family: $sigFont;
      // font-size: 1.2em;
    }
  }

}

.page-title {

  font-family: $deco;
  font-weight: 600;
  font-size: 2em;
  padding: 1em 0;
  text-align: center;
}

.photos {

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 800px;
  margin: 0 auto;
  .photo-wrapper {

    width: 45%;
    img {
      border: 4px solid black;
      width: 100%;
    }
  }
}


.menu-category-list-view {
  .mag-glass-svg {
    display: none;

  }
}


.page-title {
  text-align: center;
}
.contact-page {
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 2em;
  padding: 0 0.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  ul {
    list-style: disc;
    padding-left: 1em;
  }
  li {
    margin: 0.5em 0;
  }
}
.footer {
  color: black;
  background-color: #f4f4f4 !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='52' height='52' viewBox='0 0 52 52'%3E%3Cpath fill='%23e1e1e1' fill-opacity='0.4' d='M0 17.83V0h17.83a3 3 0 0 1-5.66 2H5.9A5 5 0 0 1 2 5.9v6.27a3 3 0 0 1-2 5.66zm0 18.34a3 3 0 0 1 2 5.66v6.27A5 5 0 0 1 5.9 52h6.27a3 3 0 0 1 5.66 0H0V36.17zM36.17 52a3 3 0 0 1 5.66 0h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 0 1 0-5.66V52H36.17zM0 31.93v-9.78a5 5 0 0 1 3.8.72l4.43-4.43a3 3 0 1 1 1.42 1.41L5.2 24.28a5 5 0 0 1 0 5.52l4.44 4.43a3 3 0 1 1-1.42 1.42L3.8 31.2a5 5 0 0 1-3.8.72zm52-14.1a3 3 0 0 1 0-5.66V5.9A5 5 0 0 1 48.1 2h-6.27a3 3 0 0 1-5.66-2H52v17.83zm0 14.1a4.97 4.97 0 0 1-1.72-.72l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1 0-5.52l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43c.53-.35 1.12-.6 1.72-.72v9.78zM22.15 0h9.78a5 5 0 0 1-.72 3.8l4.44 4.43a3 3 0 1 1-1.42 1.42L29.8 5.2a5 5 0 0 1-5.52 0l-4.43 4.44a3 3 0 1 1-1.41-1.42l4.43-4.43a5 5 0 0 1-.72-3.8zm0 52c.13-.6.37-1.19.72-1.72l-4.43-4.43a3 3 0 1 1 1.41-1.41l4.43 4.43a5 5 0 0 1 5.52 0l4.43-4.43a3 3 0 1 1 1.42 1.41l-4.44 4.43c.36.53.6 1.12.72 1.72h-9.78zm9.75-24a5 5 0 0 1-3.9 3.9v6.27a3 3 0 1 1-2 0V31.9a5 5 0 0 1-3.9-3.9h-6.27a3 3 0 1 1 0-2h6.27a5 5 0 0 1 3.9-3.9v-6.27a3 3 0 1 1 2 0v6.27a5 5 0 0 1 3.9 3.9h6.27a3 3 0 1 1 0 2H31.9z'%3E%3C/path%3E%3C/svg%3E");
  .content {
    // background: none;
    // display: none;
  }
  &::after {
    // content: "powered by Square";
    color: black;
  }
}
